<template>
  <div v-if="$route.name==='risk_goods_pictrue'" class="goods-image-box">
    <header class="search-top">
      <div>
        <span>审核状态：</span>
        <el-select v-model="params.state" @change="getList">
          <el-option label="待审核" :value="1"></el-option>
          <el-option label="通过" :value="2"></el-option>
          <el-option label="不通过" :value="3"></el-option>
        </el-select>
      </div>
      <div>
        <el-button @click="$router.push('/risk/goods/pictrue/history')">查看处理记录</el-button>
      </div>
    </header>
    <div class="goods-contain">
      <ul class="goods-ul">
        <li v-for="item in list.data" :key="item.id" class="goods-li">
          <div class="goods-image" @click.left="item.sel=!item.sel" @contextmenu.prevent="mouseclick(item)">
            <span v-if="item.state == 1" class="sel-icon" :class="{'active': item.sel}">
              <i class="el-icon-check"></i>
            </span>
            <div v-if="item.state != 1" class="label" :class="{'active': item.state == 3}">
              <label :class="{'active': item.state == 3}">
                <i :class="item.state == 2 ? 'el-icon-check': 'el-icon-close'"></i>
                <span>{{item.state == 2?'通过':'不通过'}}</span>
              </label>
            </div>
            <img :src="item.image_url" />
          </div>
          <p class="goods-li-footer">
            <a :href="item.goods_url" target="_blank">商品详情</a>
            <a :href="item.admin_detail_url" target="_blank">后台详情</a>
          </p>
        </li>
      </ul>
    </div>
    <footer class="table-footer">
      <div>
        <el-button type="primary" @click="contrl(2)">批量通过</el-button>
        <el-button type="primary" @click="contrl(3)">批量不通过</el-button>
        <el-button type="primary" @click="selAll()">全选</el-button>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </footer>
    <goodsdetail v-model="visible" :info="imageInfo"></goodsdetail>
  </div>
<router-view v-else />
</template>
<script>
import { defineComponent, reactive, ref } from '@vue/composition-api'
import goodsdetail from './components/goods_detail.vue'

export default defineComponent({
  components: { goodsdetail },
  setup(props, { root }) {
    const total = ref(0)
    const flag = ref(false)
    const visible = ref(false)
    const imageInfo = ref({})
    const params = reactive({
      state: 1,
      page: 1,
      limit: 10
    })
    const list = reactive({ data: [] })
    const getList = () => {
      root.$axios
      .get("/rcp/goodsManage/goodsImage/list", {
        params,
      })
      .then((res) => {
        const arr = res.data.list;
        arr.forEach(item=>{
          item.sel = false
        })
        list.data = arr.slice()
        total.value = res.data.total;
      });
    }
    getList()
    const mouseclick = val => {
      root.$axios
      .get("/rcp/goodsManage/goodsImage/info", {
        params: {
          id: val.id,
        }
      })
      .then((res) => {
        if(res.code === 10000) {
          imageInfo.value = res.data
          visible.value = true
        }
      })
      
    }
    const handleSizeChange = val => {
      params.limit = val
      params.page = 1
      getList()
    }
    const handleCurrentChange = val => {
      params.page = val
      getList()
    }
    const selAll = () => {
      flag.value = !flag.value
      list.data.forEach(item=>{
        if(!item.sel) {
          flag.value = true
        }
      })
      list.data.forEach(item=>{
        item.sel = flag.value
      })
    }
    const contrl = async val => {
      const arr = []
      list.data.forEach(item=>{
        if(item.state == 1 && item.sel) {
          arr.push({
            id: item.id,
            state: val
          })
        }
      })
      if(arr.length == 0) {
        root.$message.warning('请勾选图片')
        return
      }
      const confirm = await root.$goDialog(`确定${val == 2?'通过':'不通过'}吗？`)
      if(!confirm) return
      root.$axios
      .post("/rcp/goodsManage/goodsImage/check", {
        check_list: arr
      })
      .then((res) => {
        if(res.code === 10000) {
          root.$message.success(res.msg)
          getList()
        }
      })
      
    }
    return {
      params,
      total,
      list,
      imageInfo,
      visible,
      handleSizeChange,
      handleCurrentChange,
      getList,
      contrl,
      selAll,
      mouseclick
    }
  },
})
</script>
<style lang="scss" scoped>
.goods-image-box{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.search-top {
  display: flex;
  margin-top: 15px;
  & > div {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    span {
      font-size: 14px;
      white-space: nowrap;
      text-align: right;
      flex-shrink: 0;
    }
    &:nth-of-type(2) {
      span {
        width: 70px;
      }
    }
    &:nth-of-type(3) {
      .input {
        width: 350px;
      }
    }
  }
}
.goods-contain{
  margin-top: 15px;
  overflow: auto;
  height: 100%;
}
.goods-ul{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .goods-li{
    width: 23%;
    flex-shrink: 0;
    position: relative;
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid #f4f5fb;
    background-color: #fff;
    margin-right: 2%;
    .goods-image{
      width: 100%;
      cursor: pointer;
      background-color: #f4f5fb;
      position: relative;
      .sel-icon{
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        border: 1px solid #ccc;
        left: 10px;
        top: 10px;
        background-color: #fff;
        line-height: 24px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        &.active{
          background-color: #4F5BFF;
          border-color: #4F5BFF;
        }
      }
      .label{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        &.active{
          background-color: rgba(238, 238, 238, 0.8);
        }
        label {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          width: 110px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          background-color: rgba(73, 198, 151, 0.9);
          border-radius: 21px;
          color: #fff;
          font-size: 16px;
          i{
            margin-right: 5px;
          }
          &.active{
            background-color: rgba(241, 79, 70, 0.9);
          }
        }
      }
      img{
        width: 100%;
        height: 300px;
        object-fit: contain;
        cursor: pointer;
      }
    }
    .goods-li-footer{
      margin-top: 8px;
      display: flex;
      justify-content: space-around;
      position: relative;
      &::after{
        position: absolute;
        content: ' ';
        left: 50%;
        height: 20px;
        width: 1px;
        background-color: #f4f5fb;
      }
      span{
        cursor: pointer;
        color: $mainColor;
      }
      a{
        cursor: pointer;
        color: $mainColor;
      }
    }
  }
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>