<template>
<div v-if="show" class="goods-wrap" @click="close">
  <div class="goods-detail" @click.stop>
    <img :src="info.image_url" />
    <p>站点来源：{{info.site_name}}</p>
    <p>支付时间：{{info.pay_at}}</p>
    <p>商品链接：{{info.goods_url}}</p>
    <p>ITEM_ID：{{info.item_id}}</p>
  </div>
</div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  model: {
    prop: 'show',
    event: 'change'
  },
  props: {
    show: { type: Boolean, default: false },
    info: { type: Object, default: () => {} },
  },
  setup(props, { emit }) {
    const close = () => {
      emit('change', false)
    }
    return {
      close
    }
  },
})
</script>
<style lang="scss" scoped>
.goods-wrap{
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}
.goods-detail{
  width: 720px;
  height: 550px;
  position: absolute;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  background-color: #fff;
  border-radius: 8px;
  img{
    // width: 100%;
    display: block;
    margin: 0 auto;
    height: 400px;
    object-fit: contain;
  }
  p{
    padding: 4px 15px;
  }
}
</style>